import React from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { Link } from "gatsby"

import { PageSegment, TitleAndDescription } from ".."
import { GridOneColumn } from "../../styles"

import blog301Image from "../../assets/images/301-blog.png"
import creatives from "../../assets/images/creatives.png"
import ourStory from "../../assets/images/our-story.png"


const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Welcome = () => {
  return (
    <PageSegment>
      <GridOneColumn>
        <TitleAndDescription title="Welcome">
          <p>
            Welcome to the CreativeHub301, the home for professional artists and
            makers showcasing their new and original work to the general public.
          </p>
        </TitleAndDescription>
        <FlexContainer>
          <SquareImageButton
            image={blog301Image}
            text="301 Blog"
            to="/301-blog"
          />
          <SquareImageButton
            image={creatives}
            text="Creative"
            to="/creatives"
          />
          <SquareImageButton
            image={ourStory}
            text="Our Story"
            to="/our-story"
          />
        </FlexContainer>
      </GridOneColumn>
    </PageSegment>
  )
}

const center = () => {
  return `display: flex; justify-content: center; align-items: center;`
}

const SquareImageLink = styled(Link)<{ image: any }>`
  text-decoration: none;
  color: var(--black);
  margin: 18px;
  width: 275px;
  height: 275px;
  background-image: url(${props => props.image});
  background-size: cover;

  ${center}

  .square {
    width: 180px;
    height: 82px;
    background-color: var(--white);
    font-weight: 700;
    font-size: 1.2em;
    ${center}
  }
`

type Props = {
  image: any
  text: string
  to?: string
}

const SquareImageButton = ({ image, text, to = "/creatives" }: Props) => {
  return (
    <SquareImageLink image={image} to={to}>
      <div className="square">{text}</div>
    </SquareImageLink>
  )
}
