import { useState } from 'react';

export function useForm(defaults:any) {
  const [values, setValues] = useState(defaults);

  function updateValue(e:any) {
    // check if its a number and convert
    let { value } = e.target;
    if (e.target.type === 'number') {
      value = parseInt(e.target.value);
    }
    setValues({
      // copy the existing values into it
      ...values,
      // update the new value that changed
      [e.target.name]: value,
    });
  }

  return { values, updateValue, setValues };
}
