import React, { useRef } from "react"
import styled from "styled-components"
import Flicking from "@egjs/react-flicking"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { PageSegment, TitleAndDescription } from ".."
import { GridOneColumn } from "../../styles"

import carouselButtonLeft from "../../assets/images/carousel-button-left.svg"
import carouselButtonRight from "../../assets/images/carousel-button-right.svg"

export const Features = () => {
  return (
    <PageSegment background="var(--white)">
      <GridOneColumn>
        <TitleAndDescription title="Features" rightSide={false}>
          <p>
            Check out the amazing products from our Creatives and their stories.
            From Fine Art, Luxury Pet Accessories, Organic Care Products and
            much more... All under one hub, The Creative Hub 301
          </p>
        </TitleAndDescription>
        <ImageGallery />
      </GridOneColumn>
    </PageSegment>
  )
}

const ImageGalleryContainer = styled.div`
  width: 100%;
  height: 245px;
  position: relative;
  margin: 10px;

  @media (min-width: 768px) {
    height: 400px;
  }
`

const SquareImageLink = styled(Link)`
  width: 245px;
  height: 245px;

  @media (min-width: 768px) {
    height: 400px;
    width: 400px;
  }
`

const GalleryButton = styled.button`
  width: 48px;
  height: 48px;
  background: none;
  border: none;
  outline: none;

  cursor: pointer;

  :active {
    outline: none;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  position: absolute;
  z-index: 1001;
  top: 100px;

  @media (min-width: 768px) {
    top: 177px;
  }
`

type Features = {
  features: {
    nodes: {
      name: string
      slug: string
      image: any
      id: string
    }[]
  }
}

const ImageGallery = () => {
  const ref = useRef<Flicking | null>(null)

  const query = useStaticQuery<Features>(graphql`
    query FeatureQuery {
      features: allSanityFeature {
        nodes {
          id
          name
          slug
          image {
            asset {
              fluid(maxWidth: 410) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ImageGalleryContainer>
      <Flicking
        ref={ref}
        gap={10}
        hanger={0}
        anchor={0}
        collectStatistics={false}
        bound={true}
        autoResize={true}
        zIndex={1000}
      >
        {query.features.nodes.map(node => {
          return (
            <SquareImageLink key={node.id} to={node.slug}>
              <Img fluid={node.image.asset.fluid} alt={node.name} />
            </SquareImageLink>
          )
        })}
      </Flicking>
      <ButtonContainer>
        <GalleryButton>
          <img
            src={carouselButtonLeft}
            alt="carousel-button-left"
            onClick={() => {
              ref.current!.prev()
            }}
          />
        </GalleryButton>
        <GalleryButton>
          <img
            src={carouselButtonRight}
            alt="carousel-button-right"
            onClick={() => {
              ref.current!.next()
            }}
          />
        </GalleryButton>
      </ButtonContainer>
    </ImageGalleryContainer>
  )
}
