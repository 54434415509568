import React from "react"
import styled from "styled-components"

import {
  PageSegment,
  TitleAndDescription,
  FormStyled,
  InputStyled,
  SubmitButton,
} from ".."
import { GridOneColumn } from "../../styles"

import { useForm } from "../../utils"

export const ContactUs = () => {
  return (
    <PageSegment>
      <GridOneColumn>
        <TitleAndDescription title="Contact Us">
          <p>
            We'd love to hear from you. Have a question you need answering or
            simply want to leave us feedback?
          </p>
        </TitleAndDescription>
        <ContactUsForm />
      </GridOneColumn>
    </PageSegment>
  )
}

export const ContactUsForm = () => {
  const { values, updateValue, setValues } = useForm({
    name: "",
    email: "",
    message: "",
  })

  const handleOnSubmit = () => {
    const data = {
      name: values.name,
      email: values.email,
      message: values.message,
    }

    fetch(process.env.GATSBY_CONTACT_US_API!, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log("Success:", data)

        setValues({
          name: "",
          email: "",
          message: "",
        })
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }

  return (
    <FormStyled
      onSubmit={e => {
        e.preventDefault()
        handleOnSubmit()
      }}
    >
      <InputStyled
        type="text"
        name="name"
        id="name"
        value={values.name}
        onChange={updateValue}
        placeholder="Name"
        aria-label="Name"
        aria-required="true"
      />
      <InputStyled
        type="email"
        name="email"
        id="email"
        value={values.email}
        onChange={updateValue}
        placeholder="Enter email"
        aria-label="Enter email"
        aria-required="true"
      />
      <InputStyled
        as="textarea"
        name="message"
        id="message"
        value={values.message}
        onChange={updateValue}
        placeholder="Message"
        style={{ height: "8em" }}
        data-enable-grammarly="false"
        aria-label="Messsage"
        aria-required="true"
        className="formBorderBottom"
      />
      <SubmitButton as="input" type="submit" value="Submit" />
    </FormStyled>
  )
}
