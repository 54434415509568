import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import { PageSegment } from ".."

const MainBannerContainer = styled(motion.div)`
  width: 100%;
  height: 336px;
  /* overflow: hidden; */

  opacity: 0;

  .gatsby-image-wrapper {
    width: 100%;
    height: 336px;
  }

  h1 {
    font-size: 2.5em;
    font-weight: 700;
  }

  h3 {
    font-size: 1.2em;
  }

  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -210px;
    background-color: transparent;
  }

  @media (min-width: 768px) {
    height: 540px;

    .gatsby-image-wrapper {
      width: 100%;
      height: 540px;
    }

    h1 {
      font-size: 5em;
      font-weight: 700;
    }

    h3 {
      font-size: 1.5em;
    }

    .box {
      top: -360px;
    }
  }
`

const Button = styled(Link)`
  margin-top: 25px;
  background-color: var(--black);
  color: white;
  padding: 10px 32px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  outline-style: solid;
  outline: none;

  cursor: pointer;

  :active {
    outline: none;
  }
`

const variants = {
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
}

export const MainBanner = () => {
  const query = useStaticQuery(graphql`
    query HomePageBanner {
      homeData: sanityHomeData {
        mainBannerImage {
          asset {
            fluid(maxHeight: 540, maxWidth: 1440) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)

  return (
    <PageSegment>
      <MainBannerContainer variants={variants} animate="visible">
        <Img
          fluid={query.homeData.mainBannerImage.asset.fluid}
          alt="main-banner"
        />
        <div className="box">
          <h3>A community for</h3>
          <h1>Creative People</h1>
          <Button to="/our-story">Know More</Button>
        </div>
      </MainBannerContainer>
    </PageSegment>
  )
}
