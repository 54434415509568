import React from "react"
import styled from "styled-components"

import { PageSegment, FormStyled, SubmitButton } from ".."
import { GridOneColumn } from "../../styles"
import { InputStyled } from "../Forms"

import { useForm } from "../../utils"

const Title = styled.h1`
  margin-top: 60px;
  margin-bottom: 25px;
  font-size: 1.5em;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 130px;
    font-size: 2.5em;
  }
`

const Text = styled.h4`
  text-align: center;
  width: 80%;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 50px;
  }
`

export const SubscribeNewsletter = () => {
  return (
    <PageSegment background="var(--grey)">
      <GridOneColumn>
        <Title>SUBSCRIBE TO OUR NEWSLETTER</Title>
        <Text>So you will be kept up to date about our latest events</Text>
        <NewsletterForm />
      </GridOneColumn>
    </PageSegment>
  )
}

const InputStyledNewsletter = styled(InputStyled)`
  height: 2em;
  font-size: 1.3em;
  @media (min-width: 768px) {
    height: 2em;
    font-size: 1.5em;
  }
`

const NewsletterForm = () => {
  const { values, updateValue, setValues } = useForm({
    email: "",
  })

  const handleOnSubmit = () => {
    const data = {
      email: values.email,
    }

    fetch(process.env.GATSBY_MAILCHIMP_ENDPOINT!, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log("Success:", data)

        setValues({
          email: "",
        })
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }

  return (
    <FormStyled
      onSubmit={e => {
        e.preventDefault()
        handleOnSubmit()
      }}
    >
      <InputStyledNewsletter
        type="email"
        name="email"
        id="email"
        value={values.email}
        onChange={updateValue}
        placeholder="Enter email"
        aria-label="Enter email"
        aria-required="true"
      />

      <SubmitButton
        as="input"
        type="submit"
        value="SUBSCRIBE ME!"
        style={{ marginBottom: "25px" }}
      />
    </FormStyled>
  )
}
