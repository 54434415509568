import React from "react"
import styled from "styled-components"

const TitleAndDescriptionContainer = styled.div<{ rightSide: boolean }>`
  padding-top: 2em;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    width: 100%;
    font-weight: 500;
    border-bottom: 2px solid var(--black);
    padding-bottom: 15px;
  }
  p {
    line-height: 2em;
  }
  @media (min-width: 768px) {
    width: 65%;
    justify-self: ${props => (props.rightSide ? "start" : "end")};
    align-items: ${props => (props.rightSide ? "flex-end" : "flex-start")};

    h1 {
      text-align: ${props => (props.rightSide ? "end" : "start")};
    }
    p {
      width: 65%;
    }
  }
`

type TitleAndDescriptionProps = {
  title: string
  children?: React.ReactNode
  rightSide?: boolean
  className?: string
  with768?:string
  
}

export const TitleAndDescription = ({
  title,
  children,
  rightSide = true,
  className,
}: TitleAndDescriptionProps) => {
  return (
    <TitleAndDescriptionContainer rightSide={rightSide} className={className}>
      <h1>{title}</h1>
      {children}
    </TitleAndDescriptionContainer>
  )
}
