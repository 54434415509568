import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const PaginationStyles = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;

  margin: 2rem 0;
  text-align: center;
  & > * {
    color: var(--darkGrey);
    padding: 1rem;
    flex: 1;
    border: 1px solid var(--darkGrey);
    text-decoration: none;
    height: 100%;
    &[aria-current],
    &.current {
      color: var(--white);
      background-color: var(--darkGrey);
    }
  }
  @media (max-width: 800px) {
    .word {
      display: none;
    }
    font-size: 1.4rem;
  }

  .disabled-link {
    pointer-events: none;
    color: var(--grey);
  }
`

type PaginationProps = {
  pageSize: number
  totalCount: number
  currentPage: number
  skip: number
  base: string
}

export function Pagination({
  pageSize,
  totalCount,
  currentPage,
  skip,
  base,
}: PaginationProps) {
  // make some variables
  const totalPages = Math.ceil(totalCount / pageSize)
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1
  const hasNextPage = nextPage <= totalPages
  const hasPrevPage = prevPage >= 1
  return (
    <PaginationStyles>
      <Link
        title="Prev Page"
        className={!hasPrevPage ? "disabled-link" : ""}
        to={`${base}/${prevPage}`}
      >
        ←
      </Link>
      {Array.from({ length: totalPages }).map((_, i) => (
        <Link
          className={currentPage === 1 && i === 0 ? "current" : ""}
          to={`${base}/${i > 0 ? i + 1 : ""}`}
          key={`page${i}`}
        >
          {i + 1}
        </Link>
      ))}
      <Link
        title="Next Page"
        className={!hasNextPage ? "disabled-link" : ""}
        to={`${base}/${nextPage}`}
      >
        →
      </Link>
    </PaginationStyles>
  )
}
