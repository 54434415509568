import styled from "styled-components"

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .formBorderBottom {
    border-bottom: 1px solid var(--black);
  }
`

export const InputStyled = styled.input`
  display: block;
  background-color: var(--white);
  font-size: 18px;
  width: 80%;
  max-width: 500px;
  color: var(--black);
  padding: 10px;
  border: 1px solid var(--black);
  border-bottom: 0px;
  box-sizing: border-box;
  border-radius: 0;
  :focus {
    outline: none;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--darkGrey);
    opacity: 1; /* Firefox */
  }
`

export const SubmitButton = styled.button`
  width: 80%;
  max-width: 500px;
  height: 45px;
  background-color: var(--black);
  color: white;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  outline: none;
  font-size: 1.2em;

  cursor: pointer;

  :active {
    outline: none;
    background-color: var(--white);
  }

  @media (min-width: 768px) {
    height: 56px;
    font-size: 1.5em;
    font-weight: 400;
  }
`
